<script setup lang="ts">
import { nextTick, ref } from '#imports'
import type { Placement } from '@floating-ui/dom'
import { flip, shift, size } from '@floating-ui/dom'
import type { DropDownItem } from '@rialtic/types'
import { onClickOutside } from '@vueuse/core'

import useFloatingUi from '../composables/useFloatingUi'

interface Props {
  modelValue?: DropDownItem
  items: DropDownItem[]
  placeholder?: string
  placement?: Placement
  buttonBorderRadius?: string
  minWidth?: string | number
  customDropDownClasses?: string
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  placeholder: 'Select',
  placement: 'bottom-start',
  buttonBorderRadius: 'rounded-lg',
})

const emit = defineEmits<{
  select: [item: DropDownItem]
  'update:modelValue': [item: DropDownItem]
}>()

const root = ref(null)
const button = ref(null as Element | null)
const menu = ref(null as HTMLElement | null)

const isVisible = ref(false)

const middleware = [
  flip(),
  shift({ padding: 8 }),
  // Set the default width of the dropdown to match the width of the button
  size({
    apply({ rects, elements }) {
      Object.assign(elements.floating.style, {
        width: `${rects.reference.width}px`,
      })
    },
  }),
]
const { floatingStyles, updateFloating } = useFloatingUi(
  button,
  menu,
  props.placement,
  middleware,
)

const selectItem = (item: DropDownItem) => {
  emit('update:modelValue', item)
  emit('select', item)
  isVisible.value = false
}

const show = async () => {
  await updateFloating()
  isVisible.value = true
}

onClickOutside(root, async () => {
  await nextTick()

  isVisible.value = false
})

const toggleMenu = async () => {
  if (isVisible.value) isVisible.value = false
  else show()
}

// if items change, update selected value
watch(
  () => props.items,
  (newVal, oldVal) => {
    const newSelectedItem = props.items.find(
      (item) => item.label === props.modelValue?.label,
    )

    if (!newSelectedItem) return

    if (JSON.stringify(newVal) !== JSON.stringify(oldVal))
      emit('update:modelValue', newSelectedItem)
  },
)
</script>

<template>
  <div ref="root" class="relative flex">
    <!-- figma note: using py-1 since text in figma  -->
    <button ref="button" class="w-full" type="button" @click="toggleMenu">
      <slot v-bind="{ buttonBorderRadius, modelValue, placeholder }">
        <div
          class="bg-surface flex w-full flex-1 items-center justify-between space-x-2 truncate border border-neutral-200 px-3 py-2 text-neutral-800"
          :class="buttonBorderRadius ? buttonBorderRadius : 'rounded-lg'"
        >
          <div class="subtitle-2 truncate">
            {{
              modelValue && modelValue.label != ''
                ? modelValue.label
                : placeholder
            }}
          </div>
          <div class="i-ph-caret-down-bold h-4 w-4" />
        </div>
      </slot>
    </button>
    <Teleport to="body">
      <div
        ref="menu"
        class="card z-100 absolute p-1 shadow-lg"
        :class="{
          'pointer-events-none opacity-0': !isVisible,
          'min-w-48': !minWidth,
          [customDropDownClasses ?? '']: true
        }"
        :style="[floatingStyles, minWidth ? { minWidth: `${minWidth}px` } : '']"
      >
        <ul class="flex-col space-y-1">
          <li v-for="item in items" :key="item.label">
            <slot
              v-bind="{ isVisible, item, modelValue, selectItem }"
              name="option"
            >
              <button
                class="hover:bg-surface-bg flex h-9 w-full items-center rounded-md p-2 hover:bg-neutral-100"
                type="button"
                :data-testid="`dropDown_${item.label
                  .replace(/\s/g, '-')
                  .toLowerCase()}`"
                @click="selectItem(item)"
              >
                <div
                  class="i-ph-check mr-2 h-5 w-5"
                  :class="
                    modelValue && modelValue.itemValue === item.itemValue
                      ? 'opacity-100'
                      : 'opacity-0'
                  "
                />
                <div class="body-2 max-w-9/10 truncate text-neutral-900">
                  {{ item.label }}
                </div>
              </button>
            </slot>
          </li>
        </ul>
      </div>
    </Teleport>
  </div>
</template>
